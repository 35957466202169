let apiEndpoint;

const urlParams = document.currentScript.src.replace("http://", "").replace("https://", "").split(/[/?#]/)[0];
const shop = window.Shopify.shop;

console.log(urlParams);
console.log(shop);

if (urlParams === "localhost" || urlParams === "localhost:3000" || urlParams === "localhost:3001") {
    apiEndpoint = "http://localhost:4000/node/api";
}else{
    apiEndpoint = "https://back.backinstocktech.com/node/api";
}

module.exports = {
    apiEndpoint,
    shop,
}