import { createSlice } from '@reduxjs/toolkit'

const settingsSlice = createSlice({
  name: 'clinetStore',
  initialState: {},
  reducers: {
    settingsData: (state, action) => {
      state.storeInfo = (action.payload);
    },
  }
})

export const { settingsData } = settingsSlice.actions;
export default settingsSlice.reducer;