/* eslint-disable no-var */
// var sha256 = require('js-sha256').sha256
// import moment from 'moment'

const convertToBoolean = (binary_value) => {
    if (binary_value === 0 || binary_value === '0') {
        return false
    } else {
        return true
    }
}

const convertToBinary = (boolean) => {
    if (boolean === true) {
        return 1
    } else {
        return 0
    }
}

function checkStrExistInArray(str, array) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].includes(str)) {
            return true
        } else {
            return false
        }
    }
}
/**
 * 
 * This function is called when user get cookies
 */
function getCookie(key) {
    const data = document.cookie
        .split('; ')
        .find(row => row.startsWith(key))
    if (data) {
        return data.split('=')[1]
    }
}

function getJsDate(date, format = 'DD-MM-YYYY') {
    const splitDate = date.split('-')
    switch (format) {
        case 'DD-MM-YYYY':
            return new Date(splitDate[2], Number(splitDate[1]) - 1, splitDate[0])
        case 'MM-DD-YYYY':
            return new Date(splitDate[2], Number(splitDate[0]) - 1, splitDate[1])
        case 'YYYY-MM-DD':
            return new Date(splitDate[0], Number(splitDate[1]) - 1, splitDate[2])
        default:
            break
    }
    return new Date()
}
function chnageDateSeparator(date = '', existing_separator, replace_separator) {
    return date.replaceAll(existing_separator, replace_separator)
}

function getTime(date) {
    return new Date(date).getTime()
}

function getJsTime(jsDate) {
    return (jsDate).getTime()
}

function handlePageRefresh(event) {
    const e = event || window.event
    // Cancel the event
    e.preventDefault()
    if (e) {
        e.returnValue = '' // Legacy method for cross browser support
    }
    return '' // Legacy method for cross browser support
}
function debounce(fn, time) {
    let timer
    return function (...args) {
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn(...args)
        }, time)
    }
}

// const advertiseDateCheck = (from_date, to_date) => {
//     let dateCheck = moment(new Date()).format("YYYY-MM-DD")
//     let fromdate = moment(new Date(from_date)).format("YYYY-MM-DD")
//     let todate = moment(new Date(to_date)).format("YYYY-MM-DD")

//     if (dateCheck >= fromdate && dateCheck <= todate) {
//         return true
//     } else {
//         return false
//     }
// }

// find days,year,month ago function
const findDaysDiffrent = (fromDate) => {
    let CreatedDate = new Date(fromDate);
    let today = new Date();
    let requiredDiffrentDays;

    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((CreatedDate - today) / oneDay));

    if (diffDays >= 360) {
        requiredDiffrentDays =
            Math.floor(diffDays / 360) === 1
                ? `${Math.floor(diffDays / 365)} year ago`
                : `${Math.floor(diffDays / 365)} years ago`;
    } else if (diffDays >= 30) {
        requiredDiffrentDays =
            Math.floor(diffDays / 30) === 1
                ? `${Math.floor(diffDays / 30)} month ago`
                : `${Math.floor(diffDays / 30)} months ago`;
    } else if (diffDays < 30) {
        requiredDiffrentDays =
            diffDays === 1 || diffDays === 0 ? `Today` : `${diffDays} days ago`;
    }
    return requiredDiffrentDays;
}

const htmlSpecialCharacterEncode = (text) => {
    let map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;',
        '’': '&#8217;',
        '‘': '&#8216;',
        '–': '&#8211;',
        '—': '&#8212;',
        '…': '&#8230;',
        '??': '&#8221;',
        '?': '?'
    };
    return text.replace(/[&<>"'’‘–—…??]/g, function (m) {
        return map[m];
    });
}

const htmlspecialchars_decode = (string = '', quoteStyle = 2) => {
    string = string || '';
    string = string.toString()
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    let OPTS = {
        'ENT_NOQUOTES': 0,
        'ENT_HTML_QUOTE_SINGLE': 1,
        'ENT_HTML_QUOTE_DOUBLE': 2,
        'ENT_COMPAT': 2,
        'ENT_QUOTES': 3,
        'ENT_IGNORE': 4
    }
    if (typeof quoteStyle !== 'number') {
        quoteStyle = OPTS[quoteStyle];
    }
    if (quoteStyle && OPTS.ENT_HTML_QUOTE_SINGLE) {
        string = string.replace(/&#039;/g, "'")
    }
    if (quoteStyle !== 0) {
        string = string.replace(/&quot;/g, '"')
    }
    string = string.replace(/&amp;/g, '&')
    return string
}

export { convertToBoolean, convertToBinary, checkStrExistInArray, getCookie, getJsDate, getTime, getJsTime, handlePageRefresh, chnageDateSeparator, debounce, findDaysDiffrent,
    htmlSpecialCharacterEncode,
    htmlspecialchars_decode
 }