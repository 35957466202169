import './App.css';
import Product from '../src/page/Product';
import { shop } from './config/config';
import { ApiCall } from './config/axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { settingsData } from './store/settingsSlice';

const App = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const getSettings = async () => {
    const res = await ApiCall('POST', '/back-in-stock', {
      shop: shop
    })
    if (res && res.data.status === "success" && res.data.statusCode === 200) {
      dispatch(settingsData(res.data.data));
      setData(res.data.data);
    }
  }
  
  useEffect(() => {
    getSettings();
  }, []);

  if(data.length !== 0 && data.length !== "undefined"){
    return (<>
      {window.__st.p === 'product' ? <Product allsettings={data} /> : ''}
    </>);
  }else{
    return (<></>);
  }
}

export default App;
